@font-face {
    font-family: Caveat;
    src: url(fonts/Caveat.ttf);
}

.containerFlex {
    display: flex;
    flex-direction: column;
}

.barContainerFlex {
    background-color: white;
    width: 100vw;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    margin-top: -2vh;
    margin-left: -0.5vw;
}

.barBottomBorderId {
    width: 105vw;
    height: 0.25vh;
    background-color: black;
    margin-bottom: 0vh;
    margin-left: -0.5vw;
}

.fullBarId {
    position:fixed;
}

a {
    text-decoration: none;
}

a:link {
    color: black;
}
a:visited {
    color: black;
}

p {
    line-height: 4vh;
    width: 48vw;
    padding-top: 0vh;
}


h4 {
    font-size: 1.25vw;
    line-height: 0.1vh;
    font-weight: normal;
}

.nameBarFlex {
    margin-left: 5vw;
    width: 55vw;
    font-size: 1.25vw;
    font-family: Caveat;
}

.linkBarFlex {
    width: 37vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-right: 3vw;
    font-size: 1.25vw;
    font-family: Caveat;
}

.introductionContainer {
    width: 100vw;
    height: 100vh;
    background-color: rgb(212, 242, 255);
    margin-left: -0.5vw;
    margin-top: 0vh;
}

.textId {
    font-weight: normal;
    font-size: 2vw;
    font-family: Caveat;
    margin-left: 5vw;
    margin-top: 16.5vh;
    line-height: 1vh;
}

.textId h3 {
    padding-top: 5vh;
    font-size: 2.25vw;
    font-size: 2.2vw;
    font-weight: 500;
}


.projectsDisplayContainerFlex {
    display: flex;
    flex-direction: column;
    height: 40vh;
    width: 100vw;
    background-color: rgb(212, 242, 255);
    justify-content: space-between;
    margin-top: -2vh;
    margin-left: 0vw;
}

.projectsDisplayContainerFlex h3 {
    font-size:2.75vh;
    font-family: sans-serif;
    margin-left: 30vw;
    margin-top: -35vh;
    text-align: center;
    width: 45vw;
}

.projectsDisplayContainerFlex p {
    font-size: 2vh;
    font-family: sans-serif;
    margin-left: 31vw;
    width: 45vw;
}


img {
    width: 20vw;
    margin-left: 5vw;
    margin-top: 4vh;
    border-radius: 10px;
}



